<template>
  <!-- 志愿服务 -->
  <div>
    <div>
      <!-- 晔光小站 -->
      <div
        style="
          border-bottom: 1px solid #e6e6e6;
          padding: 10px 15px;
          position: relative;
        "
      >
        <div style="display: inline-block; width: 60px">
          <img style="width: 100%" src="../../assets/guanghe.png" alt="" />
        </div>
        <div style="display: inline-block; position: absolute; top: 17px">
          <div style="font-size: 18px">光合城晔光小站</div>
          <div>志愿服务</div>
        </div>
        <div style="position: absolute; top: 25px; right: 25px">
        <router-link to="/guanghe/integral">
          <van-button type="info" size="small">志愿积分</van-button>
        </router-link>
      </div>
      </div>
      <!-- tab切换 -->
      <van-tabs v-model="active">
        <van-tab title="志愿服务"
          ><div style="font-size: 14px; text-indent: 2em; margin: 8px 15px">
            <div>
              利用信息化管理手段构建“受助一助人一互助’“奉献—回报—奉献”的服务新模式，发挥党员的先锋模范作用，建立社区道德评议机制，大力褒奖善行义举，引导党员群众储蓄爱心、传递善行，引导善行，培育心口相传的城乡社区精神，凝聚起推动社会发展的强大正能量，为推动社区创新发展、转型发展、高质量基础。
            </div>
            <div style="margin: 5px 0px">志愿服务项目:参加流程</div>
            <div>
              社区发布长期志愿项目,志愿者根据自身情况直接在项目中报名参加,成功后按时打卡,会直接记录时长并累加积分,使用积分可以兑换礼品。
            </div>
          </div>
          <div>
            <div class="zhiyuanbox" v-for="item in list" :key="item">
              <div style="display: inline-block; width: 160px; margin: 10px">
                <img style="width: 100%" :src="item.AImgUrl" alt="" />
              </div>
              <div class="zhiyuanB" style="display: inline-block">
                <div>{{ item.Title }}</div>
                <div style="width: 40%;" class="van-multi-ellipsis--l3">
                  {{ item.Theme }}
                </div>
                <div>
                  <router-link :to="'/guanghe/detail/' + item.AId"
                    ><button>查看详情</button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="已加入的志愿服务"
          ><div style="font-size: 14px; text-indent: 2em; margin: 8px 15px">
            <div>
              利用信息化管理手段构建“受助一助人一互助’“奉献—回报—奉献”的服务新模式，发挥党员的先锋模范作用，建立社区道德评议机制，大力褒奖善行义举，引导党员群众储蓄爱心、传递善行，引导善行，培育心口相传的城乡社区精神，凝聚起推动社会发展的强大正能量，为推动社区创新发展、转型发展、高质量基础。
            </div>
            <div style="margin: 5px 0px">志愿服务项目:参加流程</div>
            <div>
              社区发布长期志愿项目,志愿者根据自身情况直接在项目中报名参加,成功后按时打卡,会直接记录时长并累加积分,使用积分可以兑换礼品。
            </div>
          </div>
          <div>
            <div class="zhiyuanbox" v-for="item in lists" :key="item">
              <div style="display: inline-block; width: 160px; margin: 10px">
                <img style="width: 100%" :src="item.AImgUrl" alt="" />
              </div>
              <div class="zhiyuanB" style="display: inline-block">
                <div>{{ item.Title }}</div>
                <div style="width: 40%;" class="van-multi-ellipsis--l3">
                  {{ item.Theme }}
                </div>
                <div>
                  <router-link :to="'/guanghe/detail/' + item.AId">
                    <button>查看详情</button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import { WeGetActivityPage,WeGetMySignupActivityPage } from "@/api/RealInfo";
import { getOpenId } from "@/utils/auth";
export default {
  data() {
    return {
      list: [], //志愿列表
      lists:[], //参与的志愿列表
      dataform: { page: 1, limit: 1000, akId: 1 },
    };
  },
  created() {
    this.ActivityPage();
    this.MySignupActivityPage();
  },
  methods: {
    ActivityPage() {
      WeGetActivityPage(this.dataform).then((res) => {
        if (res.data.code == 0) {
          this.list = res.data.data;
          for (var i = 0; i < this.list.length; i++) {
            if (this.list[i].AImg == "") {
              this.list[i].AImgUrl =
                "https://tkdlapi.zhsq.cloud//upload/images/2021/8/a64a40a3-045.jpg";
            }
          }
        }
      });
    },
    MySignupActivityPage(){
      this.dataform.openID = getOpenId();
      WeGetMySignupActivityPage(this.dataform).then((res) => {
        if (res.data.code == 0) {
          this.lists = res.data.data;
          for (var i = 0; i < this.lists.length; i++) {
            if (this.lists[i].AImg == "") {
              this.lists[i].AImgUrl =
                "https://tkdlapi.zhsq.cloud//upload/images/2021/8/a64a40a3-045.jpg";
            }
          }
        }
      });
    }
  },
};
</script>
<style>
.zhiyuanbox {
  position: relative;
  width: 95%;
  margin: 15px 2.5%;
  border: 1px solid #333;
  border-radius: 8px;
}
.zhiyuanB div:nth-child(1) {
  width: 40%;
  display: inline-block;
  position: absolute;
  top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.zhiyuanB div:nth-child(2) {
  font-size: 13px;
  position: absolute;
  top: 40px;
}
.zhiyuanB button {
  position: absolute;
  right: 15px;
  bottom: 10px;
  font-size: 14px;
  border: none;
  color: #000;
}
</style>